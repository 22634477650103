import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Endpoints } from 'app/config';
import { ApiService, CommonService } from 'app/services';
import { FormsService } from 'app/services/forms.service';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-authentication-code',
  templateUrl: './authentication-code.component.html',
  styleUrls: ['./authentication-code.component.scss'],
})
export class AuthenticationCodeComponent implements OnInit, OnDestroy {
  authCode: number;
  formId: string;
  formName: string;
  authCodeVerified = false;
  signatureData: any;
  destroy$ = new Subject<boolean>();

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private api: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private formService: FormsService,
  ) {}

  ngOnInit(): void {
    this.commonService.hideSideMenu.next(false);
    this.formId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getAuthCode(this.formId);
    this.getSignatureData();
  }

  getAuthCode(formId, resend?) {
    this.spinner.show();
    const params = {
      formId: formId,
      resendAuthCode: resend,
    };

    this.api
      .post(Endpoints.sendAuthCode, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res) {
            if (params.resendAuthCode) {
              this.toastr.success('Token Resent successfully', 'Success');
            } else {
              if (res.message === 'Auth code already sent') {
                this.toastr.info('Auth code already sent', 'Success');
              } else {
                this.toastr.success('Token sent successfully', 'Success');
              }
            }
          }
        },
        err => {
          this.spinner.hide();
          this.toastr.error('Invalid Token', 'Alert');
        },
      );
  }

  getSignatureData() {
    this.formService.getVerifyToken().subscribe(data => {
      if (data) {
        this.signatureData = data;
        this.signatureData.sendDoc = true;
      }
    });
  }

  saveFormData() {
    this.spinner.show();
    if (this.signatureData) {
      this.api
        .put(Endpoints.saveForm, this.signatureData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            this.toastr.success('Form saved successfully', 'Success');
            this.authCodeVerified = true;
          }
        });
    } else {
      console.log('No data to save');
    }
  }

  verifyToken() {
    this.spinner.show();
    this.api
      .post(Endpoints.verifyToken, { authCode: this.authCode, formId: this.formId })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          if (this.signatureData) {
            this.saveFormData();
          }
          this.toastr.success('Token verified successfully', 'Success!');
        } else {
          this.toastr.error('Invalid Token', 'Alert');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
