import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Home',
    url: '/home',
    icon: 'icon-home',
  },
  {
    class: 'border-bottom',
    divider: true,
  },
  {
    divider: true,
  },
  {
    name: 'Accounts',
    url: '/accounts',
    icon: 'icon-notebook',
  },
  {
    name: 'Calendar',
    url: '/calendar',
    icon: 'icon-calendar',
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    badge: {
      text: '',
      variant: 'success',
      class: 'custom-badge-class ',
    },
  },
  {
    name: 'Matters',
    url: '/matters',
    icon: 'icon-drawer',
  },
  {
    name: 'References',
    url: '/references',
    icon: 'icon-notebook',
  },
  {
    name: 'Archives',
    url: '/archives',
    icon: 'icon-folder-alt',
  },
];

export const navClientItems: INavData[] = [
  {
    name: 'Home',
    url: '/home',
    icon: 'icon-home',
  },
  {
    class: 'border-bottom',
    divider: true,
  },
  {
    divider: true,
  },
  {
    name: 'Accounts',
    url: '/accounts',
    icon: 'icon-notebook',
  },
  {
    name: 'Calendar',
    url: '/calendar',
    icon: 'icon-calendar',
  },
  {
    name: 'Matters',
    url: '/matters',
    icon: 'icon-drawer',
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    badge: {
      text: '',
      variant: 'success',
      class: 'custom-badge-class ',
    },
  },
  {
    name: 'Archives',
    url: '/archives',
    icon: 'icon-folder-alt',
  },
];
