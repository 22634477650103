import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import * as MESSAGE from '../../../enum/info-messages.enum';
import { ApiService, AuthService, CommonService, DataService } from '../../../services';

@Component({
  selector: 'app-precedent',
  templateUrl: './precedent.component.html',
  styleUrls: ['./precedent.component.scss'],
})
export class PrecedentComponent implements OnInit, OnDestroy {
  @ViewChild('addCategoryModal') public addCategoryModal: ModalDirective;
  @ViewChild('addPrecedentModal') public addPrecedentModal: ModalDirective;

  addCategoryForm: FormGroup;
  addPrecedent: FormGroup;
  isPrecedentAdded: boolean = false;
  accessLevel;
  userDetails;
  matterDetails;
  matterTypeSelected = 'Family';
  precedentRecords;
  precedentCat = [];
  precedentValue;
  matterTypes = ['Family', 'Immigration', 'Criminal'];
  catId: any;
  editPrecedent: boolean = false;
  editCat: string;
  isExist: boolean;
  recipient: any;
  destroy$ = new Subject<boolean>();
  allowUpdate = false;

  constructor(
    private dataService: DataService,
    private commonService: CommonService,
    private api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
  ) {
    this.commonService.allowUpdate.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.allowUpdate = res;
    });
  }

  Endpoints = Endpoints;
  addCategory: boolean = false;
  selectCats: any;

  ngOnInit(): void {
    this.initializeForm();
    this.accessLevel = this.authService.getUserAccessLevel();
    const nav = this.router.url.split(/[/ ]+/).pop();

    this.isExist = this.router.url.includes('admin');
    const urlData = this.router.url;
    const matterRoute = urlData.includes('/matters') ? true : false;
    this.dataService.setMatterDetailActive(true);
    this.dataService.setAddMatterButtonActive(false);
    this.commonService.showSidebarCalendar(false);
    if (matterRoute) {
      this.dataService.setUserDetailActive(false);
      this.commonService.showSidebarCalendar(true);
    }

    if (this.dataService.matterData.getValue() !== null) {
      this.userDetails = this.dataService.matterData.getValue().userDetails;
      this.matterDetails = this.dataService.matterData.getValue().matterDetails;
      this.dataService.setMatterDetails(this.matterDetails);
    } else {
      try {
        const matter = JSON.parse(localStorage.getItem('matterDetails'));
        this.matterDetails = matter;
        this.userDetails = matter.user;
        this.dataService.setMatterDetails(this.matterDetails);
      } catch (err) {}
    }

    this.getPrecedentCategory();
    this.commonService.precedentTabChange.next('Family');
  }

  initializeForm() {
    this.addPrecedent = this.formBuilder.group({
      matterType: ['Family'],
      category: ['', Validators.required],
      precedentDesc: ['', Validators.required],
    });
    this.addCategoryForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  get getControls() {
    return this.addPrecedent.controls;
  }

  getPrecedentCategory() {
    this.spinner.show();
    this.api
      .get(`${Endpoints.precedentCat}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          this.precedentCat = response;
          this.precedentCat[0].active = true;
          this.spinner.hide();
        },
        err => {
          console.error('the error', err);
          this.spinner.hide();
        },
      );
  }

  selectCat(ev: any) {
    this.addCategoryForm.controls['name'].setValue(ev.target.value);
    this.selectCats = this.precedentCat.filter(cat => cat.name === ev.target.value)[0];
  }

  onSelectCategory(ev: any) {
    this.addPrecedent.controls['category'].setValue(ev.target.value);
    this.catId = this.precedentCat.filter(cat => cat.name === ev.target.value)[0];
  }

  onCancelCategory() {
    this.addCategoryForm.reset();
    this.addCategoryModal.hide();
  }

  onCancelPrecedent() {
    this.addPrecedent.reset();
    this.addPrecedentModal.hide();
    this.addPrecedent.controls['matterType'].setValue(this.matterTypeSelected);
  }

  onPrecedentUpdate(ev: string) {
    if (ev === 'true') {
      setTimeout(() => {
        this.getPrecedentCategory();
      }, 300);
    }
  }

  addPrecedentInfo() {
    if (this.addPrecedent.invalid) {
      return;
    }

    const length = this.commonService.precedentArray.filter(
      (item: any) => item.category._id === this.catId._id && this.addPrecedent.value.matterType === item.matterType,
    ).length;
    this.editPrecedent = true;
    const precedentData = {
      category: this.catId._id,
      precedent: this.addPrecedent.value.precedentDesc,
      matterType: this.addPrecedent.value.matterType,
      order: length + 1,
    };
    this.spinner.show();
    this.api
      .post(`${Endpoints.precedent}`, precedentData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          this.addPrecedent.reset();
          this.spinner.hide();
          this.addPrecedentModal.hide();
          this.addPrecedent.controls['matterType'].setValue(this.matterTypeSelected);
          this.dataService.setPrecedent(true);
          this.toastr.success(
            this.editPrecedent === true ? MESSAGE.TOASTR.MESSAGE_UPDATEPRECEDENT : MESSAGE.TOASTR.MESSAGE_ADDPRECEDENT,
            MESSAGE.INFO_MESSAGES.SUCCESS,
          );
          this.editPrecedent = false;
        },
        err => {
          this.spinner.hide();
          this.editPrecedent = false;
        },
      );
  }

  addNewCategory() {
    if (this.addCategoryForm.invalid) {
      return;
    }
    this.spinner.show();

    const apiEnds = this.addCategory
      ? this.api.post(`${Endpoints.precedentCat}`, this.addCategoryForm.value)
      : this.api.put(`${Endpoints.precedentCat}/${this.selectCats._id}`, this.addCategoryForm.value);
    apiEnds.pipe(takeUntil(this.destroy$)).subscribe(
      (response: any) => {
        this.addCategoryForm.reset();
        this.addCategoryModal.hide();
        this.toastr.success(
          this.addCategory === true
            ? MESSAGE.TOASTR.MESSAGE_ADDPRECEDENTCAT
            : MESSAGE.TOASTR.MESSAGE_UPDATEPRECEDENTCAT,
          MESSAGE.INFO_MESSAGES.SUCCESS,
        );
        setTimeout(() => {
          this.spinner.hide();
          this.getPrecedentCategory();
        }, 600);
      },
      err => {
        this.spinner.hide();
      },
    );
  }

  showModal(isAdd: boolean) {
    this.addCategory = isAdd;
    this.addCategoryModal.show();
  }

  savePrecedent() {
    this.addPrecedentModal.show();
  }

  onTabChange(ev: any) {
    this.matterTypeSelected = ev.heading;
    this.addPrecedent.controls['matterType'].setValue(ev.heading);
    this.commonService.precedentTabChange.next(ev.heading);
    this.allowUpdate = false;
    this.commonService.allowUpdate.next(false);
  }

  onSubTabChange(ev: any) {
    this.allowUpdate = false;
    this.commonService.allowUpdate.next(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
