import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationsService } from 'app/services/validations.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import { DOCKETS } from '../../../enum/dockets.enum';
import * as MESSAGE from '../../../enum/info-messages.enum';
import { LOCAL_STORAGE } from '../../../enum/local-storage.enum';
import { ApiService, AuthService, DataService } from '../../../services';

@Component({
  selector: 'app-add-docket',
  templateUrl: './add-docket.component.html',
  styleUrls: ['./add-docket.component.scss'],
})
export class AddDocketComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('docketModal') public docketModal: ModalDirective;

  @Input() docketInfo: any;
  @Input() clerkList;
  @Output() confirm = new EventEmitter();

  addDocketForm: FormGroup;
  caseTask: any;
  docketStartDate;
  endpoints = Endpoints;
  minuteErr: string;
  hourErr: string;
  title = MESSAGE.ALERT_MAX_DATE.TITLE;
  alertMessage = MESSAGE.ALERT_MAX_DATE.CONFIRMATION;
  confirmButton = MESSAGE.ALERT_MAX_DATE.CONFIRM;
  cancelButton = MESSAGE.ALERT_MAX_DATE.CANCEL;
  minTime = new Date();
  taskClose = false;
  isDocketSubmitted = false;
  disableAssignedTo = false;
  showModal = false;
  currentTask: any;
  calculatedHours;
  taskMinutes = 0;
  taskHours = 0;
  currentAccessLevel: number;
  currentUser: any;
  destroy$ = new Subject<boolean>();

  constructor(
    private dataService: DataService,
    private api: ApiService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private auth: AuthService,
    private validationService: ValidationsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initializeForm();

    if (!this.clerkList || this.clerkList?.length === 0) {
      await this.getClerks();
    }
    this.getTaskDetails();
    this.currentAccessLevel = this.auth.getUserAccessLevel();

    if (this.docketInfo?.isEdit) {
      this.patchDocketData(this.docketInfo);
    }

    if (this.docketInfo?.isClaim) {
      this.patchDocketData(this.docketInfo?.claimDataToSend);
    }

    try {
      this.currentUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_DETAILS));
      if (this.currentUser && this.currentUser?._id) {
        if (this.docketInfo && this.docketInfo?.taskLawyers) {
          if (this.docketInfo?.isEdit) {
            if (
              this.docketInfo.taskTitle === DOCKETS.DOCKETFILETITLE ||
              this.docketInfo.taskTitle === DOCKETS.DOCKETMATTERTITLE
            ) {
              this.disableAssignedTo = true;
              this.addDocketForm.get('assignedTo').setValue(this.docketInfo?.assignedTo);
            } else {
              this.disableAssignedTo = false;
              this.addDocketForm.get('assignedTo').setValue(this.docketInfo.assignedTo);
            }
          } else {
            if (this.currentAccessLevel === 2) {
              this.addDocketForm.get('assignedTo').setValue(this.currentUser._id);
            } else {
              this.addDocketForm
                .get('assignedTo')
                .setValue(this.docketInfo?.taskLawyers[0]?._id || this.currentUser._id);
            }

            this.addDocketForm.get('taskDate').setValue(new Date());
            this.addDocketForm.get('taskMinutes').setValue(1);
            this.addDocketForm.get('taskStart').setValue(moment().format('HH:mm'));
          }
        } else {
          if (!this.docketInfo?.isEdit) {
            this.addDocketForm.get('assignedTo').setValue(this.currentUser._id);
          }
        }
      }
    } catch (err) {}
  }

  /**
   * @description Method is used to get the cleek
   */
  getClerks() {
    return new Promise((resolve, reject) => {
      this.api
        .get(this.endpoints.getClerks)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            if (data) {
              this.clerkList = [...data];
              resolve(data);
            }
          },
          error => {
            const loginErr = error.error || error.message || MESSAGE.RESPONSE.ERROR;
            this.toastr.error(loginErr, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
            reject(error);
          },
        );
    });
  }

  /**
   *
   * @param docketData
   * @description Method is used to patch the form control values
   */
  patchDocketData(docketData: any) {
    this.addDocketForm.patchValue({
      taskTitle: docketData?.taskTitle || docketData?.claimTitle,
      taskDescription: docketData?.taskDescription?.replace(/<br\s*\/?>/gi, '\n'),
      taskDate: docketData?.taskDate,
      taskStart: docketData?.taskStart,
      taskEnd: docketData?.taskEnd,
      taskLocation: docketData?.taskLocation,
      mapsUrl: docketData?.mapsUrl,
      taskHours: docketData?.taskHours,
      taskMinutes: docketData?.taskMinutes,
      taskCloseDate: docketData?.taskCloseDate,
      assignedTo: docketData?.assignedTo,
    });

    // check if claim then set the task date to current date
    if (this.docketInfo?.isClaim) {
      this.addDocketForm.patchValue({
        taskDate: moment().format('YYYY-MM-DD'),
      });
    }
  }

  ngOnChanges() {
    setTimeout(() => {
      this.docketModal.show();
    }, 100);
  }

  /**
   *
   * @param ev
   * @description Method is used to set the assigned to id.
   */
  setCompletedBy(ev: any) {
    this.addDocketForm.get('assignedTo').setValue(ev.target.value);
  }

  /**
   * @description Method is used to create the form group for the dockets
   */
  initializeForm() {
    this.addDocketForm = this.fb.group({
      taskTitle: ['', [Validators.required, Validators.maxLength(300), this.validationService.noWhitespaceValidator()]],
      taskDescription: [''],
      taskDate: ['', Validators.required],
      taskStart: ['', Validators.required],
      taskEnd: [''],
      taskLocation: [''],
      mapsUrl: [''],
      taskHours: [''],
      taskMinutes: ['', Validators.required],
      taskCloseDate: [''],
      assignedTo: ['', Validators.required],
    });

    if (this.docketInfo?.isEdit || this.docketInfo.isClaim) {
      this.checkCaseTask();
    } else {
      this.addDocketForm.get('taskHours').setValue(0);
      this.addDocketForm.get('taskMinutes').setValue(0);
    }
  }

  /**
   * @description Method is used to check if the task is case progress
   */
  checkCaseTask() {
    const apiEndPoint = this.api.getParams(`${this.endpoints.getCaseTask}`, {
      title: this.docketInfo?.claimDataToSend?.claimTitle,
      matterId: this.docketInfo?.matterId,
    });

    apiEndPoint.pipe(takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        this.caseTask = res;
        const taskStartTime = moment(`${this.caseTask?.taskDate}  ${this.caseTask?.taskStart}`, 'YYYY-MM-DD HH:mm');
        const endTime = moment(`${this.caseTask?.taskDate}  ${this.caseTask?.taskEnd}`, 'YYYY-MM-DD HH:mm');
        const timeDiff = moment.duration(endTime.diff(taskStartTime));
        const onlyHours = timeDiff.asHours();
        const totalMinutes = timeDiff.asMinutes();

        this.calculatedHours = +this.calculateHours(totalMinutes);
        this.calculatedHours = parseFloat(this.calculatedHours).toFixed(1);
        this.caseTask.taskHours = Math.trunc(onlyHours);
        this.caseTask.taskMinutes = Math.abs(totalMinutes % 60);
        this.patchDocketData(this.caseTask);

        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      },
    );
  }

  getTaskDetails() {
    const apiEndPoint = this.api.getParams(`${this.endpoints.getCaseTask}`, {
      title: this.docketInfo?.claimDataToSend?.claimTitle,
      matterId: this.docketInfo?.matterId,
    });

    apiEndPoint.pipe(takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        this.currentTask = res;
        if (this.docketInfo.isClaim) {
          this.addDocketForm.get('taskTitle').setValue(this.docketInfo.claimDataToSend.claimTitle.toString());
          this.addDocketForm.get('taskDate').setValue(new Date());
          this.addDocketForm.get('taskDescription').setValue(this.currentTask?.taskDescription);
          this.addDocketForm.get('taskStart').setValue(this.currentTask?.taskStart);
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      },
    );
  }

  /**
   *
   * @param ev
   * @param whichType
   * @returns Billable hours and error message if the minutes or hours are wrong
   */
  checkMinuteValid(ev: any, whichType: string) {
    if (whichType === 'hours') {
      const hr = +ev.target.value;
      this.taskMinutes = +this.addDocketForm.value.taskMinutes;
      this.taskHours = hr * 60;
    }
    if (whichType === 'minutes') {
      this.taskHours = +this.addDocketForm.value.taskHours * 60;
      this.taskMinutes = +ev.target.value;
    }
    this.taskHours = this.taskHours + this.taskMinutes;

    this.calculatedHours = this.calculateHours(this.taskHours);
    this.calculatedHours = parseFloat(this.calculatedHours).toFixed(1);

    if (ev.target.value > 60) {
      whichType === 'minutes'
        ? (this.minuteErr = MESSAGE.TOASTR.MESSAGE_VALIDNUMBER)
        : (this.hourErr = MESSAGE.TOASTR.MESSAGE_VALIDHOURS);
      return false;
    } else {
      whichType === 'minutes' ? (this.minuteErr = '') : (this.hourErr = '');
      return true;
    }
  }

  /**
   *
   * @param ev
   * @param whichType hours/minutes
   * @description Method is used to validate minutes
   */

  validateMinutes(ev: any, whichType: string) {
    if (whichType === 'minutes') {
      if (ev.target.value > 59) {
        this.addDocketForm.controls['taskMinutes'].setValue(59);
      } else {
        this.addDocketForm.controls['taskMinutes'].setValue(ev.target.value);
      }
    }
  }

  /**
   *
   * @param minutes
   * @returns Billable hours
   */
  calculateHours(minutes: number): number {
    let unitTime;
    try {
      unitTime = +localStorage.getItem(LOCAL_STORAGE.UNIT_TIME);
    } catch (error) {}
    unitTime = unitTime && unitTime !== 0 ? unitTime : 6;
    return Math.ceil((minutes || 0) / unitTime) / 10;
  }

  /**
   *
   * @param formdata
   * @description Method is used to insert/update dockets
   */
  insertDockets(formdata: any) {
    let apiRequest;
    let data = formdata;
    if (this.docketInfo?.isEdit || this.caseTask !== undefined) {
      formdata.completed = true;
      formdata.taskId = this.docketInfo?.isEdit ? this.docketInfo.taskId : this.caseTask._id;
      apiRequest = this.api.put(this.endpoints.matterTask, formdata);
    } else {
      formdata.completed = true;
      apiRequest = this.api.post(this.endpoints.matterTask, formdata);
    }

    apiRequest.pipe(takeUntil(this.destroy$)).subscribe(
      (res: any) => {
        if (res) {
          this.addDocketForm.reset();
          this.docketModal.hide();
          this.isDocketSubmitted = false;
          this.dataService.setTasks(true);
          this.confirm.emit({
            isAdd: true,
            showWarning: res?.isShowWarning,
            docketDate: this.addDocketForm.controls['taskDate'].value
              ? this.addDocketForm.controls['taskDate'].value
              : this.docketInfo?.claimDataToSend?.claimDate || null,
            formData: data,
          });
          this.docketInfo.isClaim
            ? this.toastr.success(MESSAGE.TOASTR.MESSAGE_CLAIM, MESSAGE.INFO_MESSAGES.SUCCESS)
            : this.docketInfo?.isEdit
            ? this.toastr.success(MESSAGE.TOASTR.MESSAGE_DOCKETUPDATED, MESSAGE.INFO_MESSAGES.SUCCESS)
            : this.toastr.success(MESSAGE.TOASTR.MESSAGE_DOCKETADDED, MESSAGE.INFO_MESSAGES.SUCCESS);
        }
        this.spinner.hide();
      },
      error => {
        let errMessage = error.error || MESSAGE.ERROR_MESSAGE.RESPONSE_MESSAGE;
        this.spinner.hide();
        this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
      },
    );
  }

  /**
   *
   * @param claimDataToSend
   * @param formdata
   * @description Method is used to complete the claim step
   */
  completeClaimStep(claimDataToSend: any, formdata: any) {
    claimDataToSend.claimDate = formdata.taskDate;
    formdata.taskCloseDate = formdata.taskDate;
    claimDataToSend.additional.filter(claim => {
      if (claim.claimTitle === claimDataToSend.claimTitle) {
        claim.date = formdata.taskDate;
        claim.completed = true;
      }
      return claim;
    });
    this.spinner.show();
    this.api
      .put(this.endpoints.matterClaimStep, claimDataToSend)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        res => {
          if (res) {
            this.insertDockets(formdata);
          }
        },
        error => {
          let errMessage = error.error || MESSAGE.ERROR_MESSAGE.RESPONSE_MESSAGE;
          console.log('** ->  ~ file: add-docket.component.ts:396 ~ completeClaimStep ~ errMessage:', errMessage);
          this.spinner.hide();
          this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        },
      );
  }

  /**
   *
   * @returns
   */
  addDockets() {
    this.isDocketSubmitted = true;

    if (!this.addDocketForm.valid) {
      this.addDocketForm.markAllAsTouched();
      return false;
    }
    if (this.addDocketForm.controls['taskHours'].value == 0 && this.addDocketForm.controls['taskMinutes'].value == 0) {
      this.toastr.error(MESSAGE.TOASTR.MESSAGE_VALID_HRMIN, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
      return;
    }
    if (this.hourErr || this.minuteErr) {
      return;
    }
    const taskTitle = this.addDocketForm.controls['taskTitle'].value;

    this.spinner.show();
    const taskDesc = this.addDocketForm.controls['taskDescription'].value;
    const formdata = {
      matterId: this.docketInfo.matterId,
      clientId: this.docketInfo.clientId,
      subscriberId: this.docketInfo.subscriberId,
      taskTitle:
        this.addDocketForm.controls['taskTitle'].value !== ''
          ? taskTitle.trim()
          : this.docketInfo.completeClaimStep.claimTitle.toString().trim(),
      taskDescription:
        taskDesc !== undefined && taskDesc !== '' && taskDesc !== null ? taskDesc.replace(/\n/g, '<br/>') : '',
      taskDate: moment(this.addDocketForm.controls['taskDate'].value).format('YYYY-MM-DD'),
      taskStart: this.addDocketForm.controls['taskStart'].value,
      taskLocation: this.addDocketForm.controls['taskLocation'].value,
      mapsUrl: this.addDocketForm.controls['mapsUrl'].value,
      taskHours: this.addDocketForm.controls['taskHours'].value,
      taskMinutes: this.addDocketForm.controls['taskMinutes'].value,
      taskCloseDate: !this.docketInfo.isClaim
        ? moment(this.addDocketForm.controls['taskDate'].value).format('YYYY-MM-DD')
        : moment(this.addDocketForm.controls['taskCloseDate'].value).format('YYYY-MM-DD'),
      assignedTo: this.addDocketForm.controls['assignedTo'].value,
      completed: true,
      completedBy: this.addDocketForm.controls['assignedTo'].value,
    };

    if (this.docketInfo.isClaim) {
      if (this.addDocketForm.controls['assignedTo'].value !== this.currentUser?._id) {
        this.spinner.hide();
        return this.toastr.error('Only assignee will be able to complete this task');
      }
      this.completeClaimStep(this.docketInfo.claimDataToSend, formdata);
    } else {
      this.insertDockets(formdata);
    }
  }

  /**
   *
   * @param e
   * @param control
   * @description Method is used to format enter number
   */
  formatNumberInputForDocket(e: any, control: any) {
    this.addDocketForm.controls[control].setValue(Math.abs(e.target.value));
  }

  /**
   * @description Getter is used to return the add dockets form controls
   */
  get getDocketsControls() {
    return this.addDocketForm.controls;
  }

  cancelDocketModal() {
    this.docketModal.hide();
    this.confirm.emit({ isAdd: false });
  }

  /**
   * @description Method is used to set the start date of the dockets
   */
  setStartDate() {
    this.docketStartDate = this.addDocketForm.value.taskDate;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
