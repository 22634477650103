import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../../../../config';
import { ApiService, CommonService } from '../../../../../../services';
import { PaymentMethods } from '../../../../../../shared/config/payment-methods';
@Component({
  selector: 'app-inter-trust-transfer-record',
  templateUrl: './inter-trust-transfer-record.component.html',
  styleUrls: ['./inter-trust-transfer-record.component.scss'],
})
export class InterTrustTransferRecordComponent implements OnInit, OnDestroy {
  @ViewChild('transferModal') public transferModal: ModalDirective;
  @ViewChild('alertModal') public alertModal: ModalDirective;

  @Input() matterSpecific: boolean;
  destroy$ = new Subject<boolean>();
  matterDetails: any;
  receipts: any;
  matterSpecificReceipts: any;
  pageNo = 1;
  notFoundFlag = false;
  pageSize = 10;
  showPaymentModal = false;
  maxDate = new Date();
  PaymentMethods = PaymentMethods;
  labels = {
    Cheque: 'Cheque No',
    Cash: 'Cash Memo No',
    'Bank Transfer': 'Transaction ID',
    'Electronic Money Transfer': 'Transaction ID',
    'Credit card': 'Transaction ID',
    'Deduct from Trust': 'Transaction ID',
    'Add to Trust': 'Transaction ID',
  };
  caseList: any;
  clientInfo$: Subscription;
  addPaymentForm: FormGroup;
  errMessage: string;
  trustInfo: any;
  infoSubsc$: Subscription;
  fromData: any;
  toData: any;
  hasTrustBalance: boolean;

  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    try {
      const data: any = JSON.parse(localStorage.getItem('matterNavDetails'));
      this.matterDetails = data.matterDetails;
    } catch (err) {
      this.spinner.hide();
    }
    this.getCaseList();
  }

  addInterTrustData() {
    if (this.matterSpecific) {
      this.addPaymentForm.get('from').setValue(this.matterSpecific ? this.matterDetails?._id : '');
      this.getTrustBalance(this.matterDetails._id);
    }
  }

  getCaseList() {
    this.clientInfo$ = this.apiService
      .get(Endpoints.getCaseFiles)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (cases: any) => {
          this.caseList = cases;
          this.spinner.hide();
        },
        err => {
          this.notFoundFlag = true;
          this.spinner.hide();
        },
      );

    this.initializeForm();
  }

  initializeForm() {
    this.addPaymentForm = this.fb.group({
      paymentMethod: [''],
      refId: [''],
      lastFourDigits: [''],
      amount: ['', Validators.required],
      purposeOfFunds: [''],
      date: [new Date()],
      creditType: [''],
      from: [this.matterSpecific ? this.matterDetails?._id : '', Validators.required],
      trustBalance: [''],
      to: ['', Validators.required],
    });

    this.getList();
  }

  async getList() {
    this.spinner.show();

    if (this.matterSpecific) {
      this.addPaymentForm.get('from').disable();
      this.matterSpecificReceipts = await this.commonService
        .getInterTrustData(this.matterDetails?._id, this.matterSpecific)
        .catch(err => {
          this.spinner.hide();
        });

      this.receipts = this.matterSpecificReceipts.filter(
        data => data?.interTrustId === this.matterDetails?._id && data?.transferType === 'debit',
      );

      this.spinner.hide();
    } else {
      this.receipts = await this.commonService
        .getInterTrustData(this.matterDetails?._id, this.matterSpecific)
        .catch(err => {
          this.spinner.hide();
        });
    }
  }

  get getPaymentControls() {
    return this.addPaymentForm.controls;
  }

  validateNumber(ev: any, controlName: string) {
    if (ev.target.value < 0) {
      this.addPaymentForm.get(controlName).setValue(0);
    }
    this.errMessage = `Transfer amount should be less than or equal to trust balance.`;
  }

  checkTrustBalance(ev: any) {
    this.fromData = this.caseList.find(c => c._id === ev.target.value);
    this.getTrustBalance(ev.target.value);
  }

  setToId(ev: any) {
    this.toData = this.caseList.find(c => c._id === ev.target.value);
    if (this.matterDetails?._id === ev.target.value) {
      this.toastr.error('The Sender cannot be the Receiver');
      this.addPaymentForm.get('to').setValue('');
    }
  }

  getTrustBalance(matterId: string) {
    this.spinner.show();
    this.infoSubsc$ = this.apiService
      .get(`${Endpoints.getTrustBalance}${matterId}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (trust: any) => {
          this.trustInfo = trust;
          const trustBal = +this.trustInfo?.trustBalance?.$numberDecimal;

          this.addPaymentForm.get('trustBalance').setValue(trustBal?.toFixed(2));
          this.addPaymentForm.get('trustBalance').disable();
          this.addPaymentForm.get('amount').setValidators(Validators.max(+trustBal?.toFixed(2)));
          this.addPaymentForm.get('amount').setValue(trustBal?.toFixed(2));
          this.addPaymentForm.updateValueAndValidity();

          if (trustBal === 0) {
            this.alertModal.show();
            this.hasTrustBalance = true;
            this.addPaymentForm.get('amount').disable();
            this.addPaymentForm.get('purposeOfFunds').disable();
            this.addPaymentForm.get('to').disable();
          } else {
            this.transferModal.show();
            this.hasTrustBalance = false;
            this.addPaymentForm.get('amount').enable();
            this.addPaymentForm.get('purposeOfFunds').enable();
            this.addPaymentForm.get('to').enable();
          }
          this.spinner.hide();
        },
        err => {
          this.alertModal.show();
          this.addPaymentForm.get('trustBalance').setValue(0);
          this.addPaymentForm.get('trustBalance').disable();
          this.addPaymentForm.get('amount').disable();
          this.addPaymentForm.get('purposeOfFunds').disable();
          this.addPaymentForm.get('to').disable();
          this.hasTrustBalance = true;
          this.spinner.hide();
        },
      );
  }

  addPayment() {
    this.spinner.show();
    this.addPaymentForm.get('trustBalance').enable();
    const balance = +this.addPaymentForm.get('trustBalance').value;
    if (balance === 0) {
      this.toastr.warning('Insufficient trust balance for the selected case.', 'Cannot proceed');
      this.spinner.hide();
      return;
    }
    const params: any = {
      amount: +this.addPaymentForm.value.amount,
      creditType: 'inter trust transfer',
      from: this.matterSpecific ? this.matterDetails?._id : this.addPaymentForm.value.from,
      fromId: this.matterSpecific ? this.matterDetails?.clientId : this.fromData?.clientId,
      lastFourDigits: this.addPaymentForm.value.lastFourDigits,
      paymentMethod: this.addPaymentForm.value.paymentMethod,
      date: this.addPaymentForm.value.date,
      purposeOfFunds: this.addPaymentForm.value.purposeOfFunds,
      refId: this.addPaymentForm.value.refId,
      to: this.addPaymentForm.value.to,
      toId: this.toData.clientId,
      trustBalance: this.addPaymentForm.value.trustBalance,
      trustId: this.trustInfo._id,
      interTrustId: this.matterDetails?._id,
      toMatterId: this.toData?._id,
    };

    this.apiService
      .put(Endpoints.interTrustTransfer, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: any) => {
          this.transferModal.hide();
          this.spinner.hide();
          this.toastr.success(data.message);
          this.getList();
        },
        err => {
          console.log(
            'TCL ->  ~ file: inter-trust-transfer-record.component.ts ~ line 138 ~ InterTrustTransferRecordComponent ~ this.apiService.put ~ err',
            err,
          );
          this.toastr.error(err.error);
        },
      );
  }

  closeModal() {
    this.alertModal.hide();
  }

  cancel() {
    this.addPaymentForm.reset();
    this.transferModal.hide();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
