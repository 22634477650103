import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as ExportToCsv from 'export-to-csv';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Endpoints } from '../../../../../config';
import { ApiService, CommonService } from '../../../../../services';
import { PdfService } from './../../../../../services/pdf.service';
@Component({
  selector: 'app-clients-trust',
  templateUrl: './clients-trust.component.html',
  styleUrls: ['./clients-trust.component.scss'],
})
export class ClientsTrustComponent implements OnInit, OnDestroy {
  @ViewChild('showClientLedger') public showClientLedgerModal: ModalDirective;
  @Input() matterSpecific = false;
  destroy$ = new Subject<boolean>();
  endPoints = Endpoints;
  pageNo = 1;
  notFoundFlag = false;
  trustBalance = 0;
  dataRange = '';
  transferType = 'ledger';
  pageLimit = 10;
  totalPages: number;
  clientLedgerList = [];
  matterDetails: any;
  totalBal = 0;
  balanceInfo = [];
  clientList: any = [];
  list: any;
  clientInfo: any;
  selectedMatterId = null;
  clientName: any;
  exportData = [];
  totalBalance = 0;
  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private pdfService: PdfService,
  ) {}

  ngOnInit(): void {
    this.matterDetails = this.commonService.getMaterData();
    this.spinner.show();
    if (!this.matterSpecific) {
      this.getClientList();
    } else if (this.matterDetails && this.matterDetails?.clientId !== '') {
      this.clientInfo = this.matterDetails.client;
      this.selectedMatterId = this.matterDetails?.matterId;
      this.getTrustReceipts(this.matterDetails?.clientId);
    }
  }

  getClientList() {
    this.apiService
      .get(this.endPoints.getAllUsersWithReciept)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (records: any) => {
          if (records == 0) {
            this.notFoundFlag = true;
          }
          this.clientList = records.filter(user => user).sort((a, b) => (a.user.firstName > b.user.firstName ? 1 : -1));
          this.sumTrustBalance(this.clientList);
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          this.notFoundFlag = true;
        },
      );
  }

  sumTrustBalance(records) {
    this.trustBalance = records.reduce((current, client) => {
      return current + client.balance;
    }, 0);
  }

  getHeading(client: any) {
    return `${client?.firstName} ${client?.lastName}`;
  }

  pageChange(ev: number) {
    this.spinner.show();
    this.pageNo = ev;
  }

  calculateBalance(clientLedgerList: any) {
    let ledger = clientLedgerList.reverse();
    return new Promise((resolve, reject) => {
      const len = clientLedgerList.length;

      this.balanceInfo = [];
      for (let l = 0; l < len; l++) {
        const receipt = ledger[l];
        if (receipt?.transferType === 'credit') {
          this.totalBal += +receipt?.amount?.$numberDecimal;
          receipt.balance = this.totalBal.toFixed(2);
          this.balanceInfo.push(+this.totalBal?.toFixed(2));
        } else if (receipt?.transferType === 'debit') {
          this.totalBal -= +receipt?.amount?.$numberDecimal;
          receipt.balance = this.totalBal.toFixed(2);
          this.balanceInfo.push(+this.totalBal.toFixed(2));
        }

        this.exportData.unshift({
          ClientName: this.clientName,
          'File No': receipt?.fileNo,
          Date: new Date(receipt.timestamps).toISOString().split('T')[0],
          Particular: Boolean(receipt?.comments)
            ? receipt?.comments
            : Boolean(receipt?.purposeOfFunds)
            ? receipt?.purposeOfFunds
            : '',
          Receipts: receipt?.transferType === 'credit' ? receipt?.amount?.$numberDecimal : '',
          Disbursement: receipt?.transferType === 'debit' ? receipt?.amount?.$numberDecimal : '',
          Balance: receipt.balance,
        });
        if (len - 1 === l) {
          resolve(true);
        }
      }
      if (this.matterSpecific) {
        clientLedgerList = ledger;
      } else {
        clientLedgerList = ledger.reverse();
      }
    });
  }

  exportCSV() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `Client's trust ledger`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv.ExportToCsv(options);

    csvExporter.generateCsv(this.exportData);
  }

  async getTrustReceipts(clientId: string, client?: any) {
    this.spinner.show();
    this.apiService
      .getParams(Endpoints.getClientLedger, {
        pageNumber: this.pageNo,
        pageSize: this.pageLimit,
        clientId,
        matterId: client ? client._id : this.matterDetails?._id,
        matterSpecific: true,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        async (data: any) => {
          let receiptsRecords = [];
          const receipts = data.receipts;
          receipts.forEach(receipt => {
            receipt?.transfer.forEach(trans => {
              trans.fileNo = receipt.matter[receipt.matter?.length - 1].matterId;

              trans.matterType = receipt.matter[receipt.matter?.length - 1].matterType;
            });
            receiptsRecords = [...receipt.transfer, ...receiptsRecords];
          });

          if (this.selectedMatterId) {
            receiptsRecords = receiptsRecords.filter(receipt => receipt.fileNo === this.selectedMatterId);
          }
          if (receiptsRecords.length > 0) {
            const sortedReceipts = receiptsRecords?.sort((a, b) => {
              let as: any = new Date(a.timestamps);
              let bs: any = new Date(b.timestamps);
              return bs - as;
            });
            const formatedBalance = await this.calculateBalance(sortedReceipts);
            this.clientLedgerList = sortedReceipts;
            this.totalPages = data.count;
            this.spinner.hide();
          } else {
            this.clientLedgerList = [];
            this.spinner.hide();
          }
        },
        err => {
          this.clientLedgerList = [];
          this.spinner.hide();
        },
      );
  }

  showClientLedgerData(client: any, matterId: any) {
    this.selectedMatterId = matterId;
    this.totalBal = 0;
    this.spinner.show();
    this.clientInfo = client.user;
    this.clientName = `${this.clientInfo.firstName} ${this.clientInfo.lastName}`;
    this.showClientLedgerModal.show();
    this.getTrustReceipts(client.clientId, client);
  }

  cancel() {
    this.showClientLedgerModal.hide();
    this.selectedMatterId = null;
    this.exportData = [];
  }

  getFile() {
    this.commonService.getFile({}, `${environment.base_url}trust/get-PDF`).subscribe(
      async (res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const fileName = `trust-disbursement-${Date.now()}.pdf`;
        const objectUrl = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
        this.spinner.hide();
      },
      err => {
        console.log('Error >', err);
      },
    );
  }

  async downloadPDF(dataRange) {
    let from = moment(dataRange[0]).format('YYYY-MM-DD');
    let to = moment(dataRange[1]).format('YYYY-MM-DD');
    this.dataRange = '';
    const filter = this.clientList.filter(
      m => new Date(m.created) >= new Date(from) && new Date(m.created) <= new Date(to),
    );

    let totalBalance = filter.reduce((a, b) => a + b.balance, 0);
    const sortedData = filter.sort((a, b) => {
      let as: any = new Date(a.created);
      let bs: any = new Date(b.created);
      return bs - as;
    });

    if (filter.length > 0) {
      const pdf = this.pdfService.clientTrustLedger(sortedData, totalBalance);
    } else {
      this.toastr.warning('Records not found for selected range.');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
