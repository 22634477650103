import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment as env } from '../../../../../environments/environment';
import { Endpoints } from '../../../../config';
import { JURISDICTION } from '../../../../config/matterType';
import { Recipients } from '../../../../config/recipients';
import * as MESSAGE from '../../../../enum/info-messages.enum';
import { ApiService, AuthService, CommonService, DataService } from '../../../../services';
import { PdfService } from '../../../../services/pdf.service';

@Component({
  selector: 'app-add-precedent',
  templateUrl: './add-precedent.component.html',
  styleUrls: ['./add-precedent.component.scss'],
})
export class AddPrecedentComponent implements OnInit, OnDestroy {
  @Input() selectedCategory: any;
  @Input() matterDetails: any;
  @Input() exist: boolean;
  @Input() matterTypeSelected: any;
  @Output() onUpdate = new EventEmitter();
  @ViewChild('addPrecedentModal') public addPrecedentModal: ModalDirective;
  @ViewChild('addPrecedentBlock') public addBlock: ModalDirective;

  currentDraggableEvent: DragEvent;
  currentDragEffectMsg: string;
  addPrecedent: FormGroup;
  addPrecedentBlock: FormGroup;
  selectRecipient: FormControl;
  precedentSelected: FormControl;
  applicantName: string;
  applicantLawyerName: string;
  respondentName: string;
  respondentLawyerName: string;
  selectedItems = [];
  recipientSelected: string;
  precedentArr: any[] = [];
  isPrecedentAdded = false;
  hideSetting = false;
  editPrecedent = false;
  showAlertModal = false;
  type: string;
  title;
  confirm;
  cancel;
  message;
  precedentId;
  destroy$ = new Subject<boolean>();
  recipients = Recipients;
  updatedList = [];
  precedentCat = [];
  exportType = 'Word Doc';
  staticCategories = [
    { label: 'Letters' },
    { label: 'Affidavits' },
    { label: 'PwrofA' },
    { label: 'Ledger' },
    { label: 'Other' },
  ];
  catId: any;
  precedentList: any = [];
  subscriberData: any;
  userMatterDetails: any;
  userLawyerDetails: any;
  sendTo: any;
  options: any = {};
  accessLevel;
  recipientAddress: string;
  setJurisdictionAddress: string;
  recipientSubsc: Subscription;
  userDetails: any;
  respondentInfoStreet = '';
  respondentInfoAddress = '';
  respondentInfoContact = '';
  respondentInfo = '';
  applicantInfoStreet = '';
  applicantInfoAddress = '';
  applicantInfoContact = '';
  applicantInfo = '';
  infoApplicantStreet = '';
  infoApplicantAddress = '';
  infoApplicantContact = '';
  infoApplicant = '';
  infoRespondentStreet = '';
  infoRespondentAddress = '';
  infoRespondentContact = '';
  infoRespondent = '';
  jury = JURISDICTION;
  tabChangeType: string;
  hideToolTip = true;
  signature: string | unknown;
  base_url = env.base_url;
  currentUser: any;
  screenWidth: boolean;
  lawyerSignature: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private api: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private pdfService: PdfService,
    private dataService: DataService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.dataService
      .refreshPrecedent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          setTimeout(() => {
            this.getPrecendents(this.matterTypeSelected);
          }, 300);
        }
      });

    this.commonService.precedentTabChange.pipe(takeUntil(this.destroy$)).subscribe(type => {
      this.spinner.show();
      this.getPrecendents(type);
    });
  }

  ngOnInit(): void {
    this.getPrecendents(this.matterTypeSelected);
    this.initializeForm();
    this.accessLevel = this.authService.getUserAccessLevel();
    const urlData = this.router.url;
    this.hideSetting = urlData.includes('/matter') ? true : false;
    this.setCategory();
    this.getLawfirm();
    this.getUserMatterDetails();
    this.getJurisdictionData();
    this.getQuestionnaire();
    this.getPrecedentCategory();
    this.recipientSelected = this.dataService.getPrecedentOptions() ? this.dataService.getPrecedentOptions()?.to : '';
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.addPrecedent) {
        this.addBlock.show();
      }
    });
  }

  getPrecedentCategory() {
    this.spinner.show();
    this.api
      .get(`${Endpoints.precedentCat}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          this.precedentCat = response;
          this.precedentCat[0].active = true;
          this.spinner.hide();
        },
        err => {
          console.error('the error', err);
          this.spinner.hide();
        },
      );
  }

  initializeForm() {
    this.selectRecipient = new FormControl('');
    this.precedentSelected = new FormControl('');
    this.addPrecedent = this.formBuilder.group({
      category: [''],
      precedentDesc: [''],
    });

    this.getSubscribedUser();

    if (this.dataService.getPrecedentOptions()) {
      const reci = this.dataService.getPrecedentOptions();
      this.selectRecipient.setValue(reci?.to);
    }
  }

  onItemSelect(item: any) {
    if (item) {
      this.options.form = item.name;
      this.dataService.setPrecedentOptions(this.options);
      this.selectedItems.push(item);
      this.selectedItems = [...new Map(this.selectedItems.map(item => [item['name'], item])).values()];
    }
  }

  onSelectAll(items: any) {
    this.selectedItems = items;
  }

  getLawfirm() {
    this.spinner.show();
    this.api
      .get(`${Endpoints.subscriber}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.subscriberData = response;
        },
        err => {
          console.error('the error', err);
          this.spinner.hide();
        },
      );
  }

  getJurisdictionData() {
    const matterData = this.userMatterDetails?.matterDetails;
    const params = {
      matterType: matterData?.matterType,
      matterSubtype: matterData?.matterSubType,
      location: matterData?.jurisdiction,
    };
    if (matterData?.jurisdiction) {
      this.spinner.show();
      this.api
        .getParams(`${Endpoints.jurisdictionAdd}`, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            this.spinner.hide();
            this.recipientAddress = response?.address;
          },
          err => {
            this.recipientAddress = '';
            this.setJurisdictionAddress = '';
            this.spinner.hide();
          },
        );
    }
  }

  async getUserMatterDetails(): Promise<void> {
    try {
      this.userMatterDetails = JSON.parse(localStorage.getItem('matterNavDetails'));

      if (this.userMatterDetails.matterDetails.lawyers.length > 0) {
        this.userLawyerDetails = this.userMatterDetails.matterDetails.lawyers[0];

        const params = { userId: this.userLawyerDetails._id };

        await this.commonService.getFile(params, `${this.base_url}${Endpoints.getSignature}`).subscribe(res => {
          this.lawyerSignature = res;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  getPrecendents(type?: string) {
    this.api.get(`${Endpoints.precedent}`).subscribe(
      (response: any) => {
        this.commonService.precedentArray = response;
        this.precedentList = response
          .filter(precedent => precedent.category._id === this.selectedCategory._id)
          .sort((a, b) => a.order - b.order);
        if (this.hideSetting) {
          this.precedentList = this.precedentList
            .filter(precedent => precedent?.matterType === this.matterDetails?.matterType)
            .sort((a, b) => a.order - b.order);
        } else {
          this.precedentList = this.precedentList
            .filter(precedent => precedent?.matterType === type)
            .sort((a, b) => a.order - b.order);
        }
        this.spinner.hide();
      },
      err => {
        console.error('the error', err);
        this.spinner.hide();
      },
    );
  }

  setCategory() {
    if (this.selectedCategory !== '') {
      this.addPrecedent.controls['category'].setValue(this.selectedCategory.name);
    }
    this.unsetCheckedPrecedent();
  }

  exportToDocx() {
    if (
      this.matterDetails?.matterType?.toLowerCase() === 'immigration' &&
      !this.matterDetails?.jurisdiction &&
      this.recipientSelected === 'Immigration and Refugee Board'
    ) {
      this.toastr.error('Jurisdiction address not found');
      return;
    }

    const applicant = this.applicantName?.trim()?.substring(this.applicantName?.lastIndexOf(' ') + 1);
    const respondent = this.respondentName?.trim()?.substring(this.respondentName?.lastIndexOf(' ') + 1);
    const participants = `${applicant || ''}${applicant && respondent ? ' and ' : ''}${respondent || ''}`;

    const filteredItems = this.recipients.filter(({ name: id1 }) =>
      this.selectedItems.some(({ name: id2 }) => id1 === id2),
    );
    const cloneArray = [...this.selectedItems];

    const precedentData = {
      clientDetails: this.userMatterDetails,
      precedentArr: this.precedentArr,
      lawfirmData: this.subscriberData ? this.subscriberData : this.subscriberData,
      recipientAdd: this.setJurisdictionAddress ? this.setJurisdictionAddress : '',
      ccImmigrationAdd: this.setJurisdictionAddress ? this.setJurisdictionAddress : '',
      selectedCC: this.matterDetails.matterType === 'Family' ? filteredItems : cloneArray,
      recipientSelected: this.recipientSelected,
      subscribedUser: this.userLawyerDetails,
      lawyerSignature: this.lawyerSignature,
      matter: participants,
      hasClaimants: this.matterDetails?.associatedClaimants.length > 0 ? true : false,
      user: this.currentUser,
    };

    this.pdfService.exportPrecedentDocx(precedentData);
    this.unsetCheckedPrecedent();
  }

  getSignature() {
    const params = { userId: this.userDetails?.userInfo?._id };
    this.commonService
      .getFile(params, `${this.base_url}${Endpoints.getSignature}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (res: any) => {
        if (res) {
          this.signature = await new Response(res).arrayBuffer();
          this.currentUser.signature = this.signature;
        }
      });
  }

  unsetCheckedPrecedent() {
    if (this.precedentList?.length > 0) {
      this.precedentList?.forEach(element => {
        element.isChecked = false;
      });
      this.precedentArr = this.precedentList.filter(data => data.isChecked);
    }
    this.selectedItems = [];
    this.selectRecipient.setValue('');
  }

  updatePrecedentInfo() {
    const precedentData = {
      category: this.selectedCategory._id,
      precedent: this.addPrecedent.value.precedentDesc,
    };

    this.spinner.show();
    this.api
      .put(`${Endpoints.updatePrecedent}/${this.precedentId}`, precedentData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          this.addPrecedent.reset();
          this.spinner.hide();
          this.addPrecedentModal.hide();

          this.toastr.success(
            this.editPrecedent === true ? MESSAGE.TOASTR.MESSAGE_UPDATEPRECEDENT : MESSAGE.TOASTR.MESSAGE_ADDPRECEDENT,
            MESSAGE.INFO_MESSAGES.SUCCESS,
          );
          this.editPrecedent = false;
          this.getPrecendents(this.matterTypeSelected);
        },
        err => {
          this.spinner.hide();
        },
      );
  }

  get getControls() {
    return this.addPrecedent.controls;
  }

  onSelectPrecedent() {
    this.precedentArr = this.precedentList.filter(data => data.isChecked);
    this.addPrecedent.reset();
  }

  onSelectCategory(ev: any) {
    this.addPrecedent.controls['category'].setValue(ev.target.value);
    let find: any = this.staticCategories.find(x => x?.label === ev.target.value);
  }

  onCancel() {
    this.addPrecedentModal.hide();
    this.addPrecedent.reset();
    this.editPrecedent = false;
  }

  onEditPrecedent(data: any, pop: any) {
    pop.hide();
    this.editPrecedent = true;
    this.precedentId = data._id;

    this.addPrecedentModal.show();
    this.addPrecedent.controls['category'].setValue(data.category.name);
    this.addPrecedent.controls['precedentDesc'].setValue(data.precedent);
  }

  showDeleteAlert(id: string, pop: any) {
    pop.hide();
    this.type = 'deletePrecedent';
    this.title = 'Warning';
    this.confirm = 'Ok';
    this.cancel = 'cancel';
    this.message =
      'Are you sure you want to delete this precedent? Editing or deleting a precedent will apply globally across all matters (current and future) within your law firm.';
    this.precedentId = id;
    this.showAlertModal = true;
  }

  onDeletePrecedent() {
    this.spinner.show();
    if (this.precedentId !== '') {
      this.api
        .delete(`${Endpoints.deletePrecedent}/${this.precedentId}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            this.addPrecedent.reset();
            this.spinner.hide();
            this.addPrecedentModal.hide();

            this.toastr.success(MESSAGE.TOASTR.MESSAGE_PRECEDENTDELETED, MESSAGE.INFO_MESSAGES.SUCCESS);
            this.editPrecedent = false;
            this.onUpdate.emit('true');
            this.getPrecendents(this.matterTypeSelected);
          },
          err => {
            let errMessage = err.error || MESSAGE.RESPONSE.ERROR;
            this.spinner.hide();
            this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
            this.onUpdate.emit('true');
          },
        );
    }
  }

  onAlertResponse(ev) {
    this.showAlertModal = !this.showAlertModal;
    if (ev.type === 'deletePrecedent') {
      if (ev.state) {
        if (this.precedentList.length === 0) {
          this.onUpdate.emit('true');
        }
        this.onDeletePrecedent();
      } else {
        this.showAlertModal = false;
        this.onUpdate.emit('false');
      }
    }
  }

  getQuestionnaire() {
    if (this.matterDetails) {
      this.api
        .getParams(`${Endpoints.getAnswer}`, {
          matterId: this.matterDetails?._id,
          clientId: this.matterDetails?.clientId,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: any) => {
          const questionnaire = result.questionnaire;
          const formSections = Object.keys(questionnaire);

          formSections.forEach(section => {
            if (section === 'APPLICANT' || section === 'RESPONDENT') {
              this.setAnswers(questionnaire[section], section);
            }
          });
        });
    }
  }

  setAnswers(fields: any, section: any) {
    if (fields?.fields?.length > 0) {
      fields.fields.forEach(field => {
        if (field?.controls?.length > 0) {
          field?.controls.forEach((control: any) => {
            if (control.controlName === 'respondentLawyerFullLegalName' && control.answer !== '')
              this.respondentLawyerName = control.answer;

            if (
              control.controlName == 'respondentLawyerStreetNo' ||
              control.controlName == 'respondentLawyerStreetName'
            ) {
              this.respondentInfoStreet += control.answer ? control.answer + ' ' : '';
            }
            if (
              control.controlName == 'respondentLawyerCity' ||
              control.controlName == 'respondentLawyerProvince' ||
              control.controlName == 'respondentLawyerPostalCode'
            ) {
              this.respondentInfoAddress += control.answer ? control.answer + ' ' : '';
            }
            if (control.controlName == 'respondentLawyerPhone' || control.controlName == 'respondentLawyerFax') {
              this.respondentInfoContact += control.answer ? control.answer + ', ' : '';
            }
            if (control.controlName == 'respondentLawyerEmail') {
              this.respondentInfo += `${this.respondentLawyerName ? this.respondentLawyerName : ''}\\n${
                this.respondentInfoStreet ? this.respondentInfoStreet.slice(0, -1) : ''
              }\\n${this.respondentInfoAddress ? this.respondentInfoAddress : ''}\\n${
                this.respondentInfoContact ? this.respondentInfoContact + '\\n' : ''
              }${control.answer ? control.answer : ''}`;
            }

            if (control.controlName === 'applicantLawyerFullLegalName' && control.answer !== '')
              this.applicantLawyerName = control.answer;

            if (
              control.controlName == 'applicantLawyerStreetNo' ||
              control.controlName == 'applicantLawyerStreetName'
            ) {
              this.applicantInfoStreet += control.answer ? control.answer + ' ' : '';
            }
            if (
              control.controlName == 'applicantLawyerCity' ||
              control.controlName == 'applicantLawyerProvince' ||
              control.controlName == 'applicantLawyerPostalCode'
            ) {
              this.applicantInfoAddress += control.answer ? control.answer + ' ' : '';
            }
            if (control.controlName == 'applicantLawyerPhone' || control.controlName == 'applicantLawyerFax') {
              this.applicantInfoContact += control.answer ? control.answer + ', ' : '';
            }
            if (control.controlName == 'applicantLawyerEmail') {
              this.applicantInfo += `${this.applicantLawyerName ? this.applicantLawyerName : ''}\\n${
                this.applicantInfoStreet ? this.applicantInfoStreet.slice(0, -1) : ''
              }\\n${this.applicantInfoAddress ? this.applicantInfoAddress : ''}\\n${
                this.applicantInfoContact ? this.applicantInfoContact + '\\n' : ''
              }${control.answer ? control.answer : ''}`;
            }

            control?.child?.forEach(child => {
              if (child.controlName === 'respondentFullLegalName' && child.answer !== '')
                this.respondentName = child.answer;

              if (child.controlName === 'applicantFullLegalName' && child.answer !== '')
                this.applicantName = child.answer;

              if (child.controlName == 'applicantStreetNo' || child.controlName == 'applicantStreetName') {
                this.infoApplicantStreet += child.answer ? child.answer + ' ' : '';
              }
              if (
                child.controlName == 'applicantCity' ||
                child.controlName == 'applicantProvince' ||
                child.controlName == 'applicantPostalCode'
              ) {
                this.infoApplicantAddress += child.answer ? child.answer + ' ' : '';
              }
              if (child.controlName == 'applicantPhone' || child.controlName == 'applicantFax') {
                this.infoApplicantContact += child.answer ? child.answer + ', ' : '';
              }
              if (child.controlName == 'applicantEmail') {
                this.infoApplicant += `${this.applicantName ? this.applicantName : ''}\\n${
                  this.infoApplicantStreet ? this.infoApplicantStreet.slice(0, -1) : ''
                }\\n${this.infoApplicantAddress ? this.infoApplicantAddress : ''}\\n${
                  this.infoApplicantContact ? this.infoApplicantContact + '\\n' : ''
                }${child.answer ? child.answer : ''}`;
              }

              if (child.controlName == 'respondentStreetNo' || child.controlName == 'respondentStreetName') {
                this.infoRespondentStreet += child.answer ? child.answer + ' ' : '';
              }
              if (
                child.controlName == 'respondentCity' ||
                child.controlName == 'respondentProvince' ||
                child.controlName == 'respondentPostalCode'
              ) {
                this.infoRespondentAddress += child.answer ? child.answer + ' ' : '';
              }
              if (child.controlName == 'respondentPhone' || child.controlName == 'respondentFax') {
                this.infoRespondentContact += child.answer ? child.answer + ', ' : '';
              }
              if (child.controlName == 'respondentEmail') {
                this.infoRespondent += `${this.respondentName ? this.respondentName : ''}\\n${
                  this.infoRespondentStreet ? this.infoRespondentStreet.slice(0, -1) : ''
                }\\n${this.infoRespondentAddress ? this.infoRespondentAddress : ''}\\n${
                  this.infoRespondentContact ? this.infoRespondentContact + '\\n' : ''
                }${child.answer ? child.answer : ''}`;
              }
            });
          });
        }
      });
    }

    if (this.matterDetails.matterType === 'Family') {
      if (this.matterDetails.partyType === 'Applicant') {
        if (!this.applicantName && !this.applicantLawyerName) this.setData(true);
        const precedent = [
          {
            name: `${this.matterDetails?.court}${
              this.userMatterDetails?.courtAddress ? ', ' + this.userMatterDetails?.courtAddress : ''
            }`,
            id: this.matterDetails?.court,
            address: `${this.matterDetails?.court}, ${
              this.matterDetails?.courtAddress ? this.matterDetails?.courtAddress : ''
            }`,
          },
          { name: this.applicantName, id: this.applicantName, address: this.infoApplicant },
          { name: this.respondentName, id: this.respondentName, address: this.infoRespondent },
          { name: this.respondentLawyerName, id: this.respondentLawyerName, address: this.respondentInfo },
        ];
        this.recipients = precedent.filter(item => Boolean(item.id));
      } else {
        if (!this.respondentName && !this.respondentLawyerName) this.setData(false);
        const precedent = [
          {
            name: `${this.matterDetails?.court}${
              this.userMatterDetails?.courtAddress ? ', ' + this.userMatterDetails?.courtAddress : ''
            }`,
            id: this.matterDetails?.court,
            address: `${this.matterDetails?.court}, ${
              this.matterDetails?.courtAddress ? this.matterDetails?.courtAddress : ''
            }`,
          },
          { name: this.applicantName, id: this.applicantName, address: this.infoApplicant },
          { name: this.respondentName, id: this.respondentName, address: this.infoRespondent },
          { name: this.applicantLawyerName, id: this.applicantLawyerName, address: this.applicantInfo },
        ];
        this.recipients = precedent.filter(item => Boolean(item.id));
      }
    }
  }

  setData(flag) {
    if (flag) {
      this.applicantName = `${this.matterDetails?.user?.firstName ? this.matterDetails?.user?.firstName + ' ' : ''}${
        this.matterDetails?.user?.lastName ? this.matterDetails?.user?.lastName : ''
      }`;
      const applicantAddress = `${
        this.matterDetails?.mailingAddress?.streetNumber ? this.matterDetails?.mailingAddress?.streetNumber + ' ' : ''
      }${
        this.matterDetails?.mailingAddress?.streetName ? this.matterDetails?.mailingAddress?.streetName + ', ' : ', '
      }${this.matterDetails?.mailingAddress?.city ? this.matterDetails?.mailingAddress?.city + ' ' : ''}${
        this.matterDetails?.mailingAddress?.province ? this.matterDetails?.mailingAddress?.province + ', ' : ', '
      }${this.matterDetails?.mailingAddress?.postalCode ? this.matterDetails?.mailingAddress?.postalCode : ''}`;
      const applicantContactNumber = this.matterDetails?.user?.mobilePhone ? this.matterDetails?.user?.mobilePhone : '';
      const applicantEmail = this.matterDetails?.user?.email ? this.matterDetails?.user?.email : '';
      this.infoApplicant = `${this.applicantName !== '' ? this.applicantName + ', ' : ''}${
        applicantAddress !== '' ? applicantAddress + ', \\n' : ''
      }${applicantContactNumber !== '' ? applicantContactNumber + ', \\n' : ''}${
        applicantEmail !== '' ? applicantEmail : ''
      }`;

      this.applicantLawyerName = `${
        this.matterDetails?.lawyers[0]?.firstName ? this.matterDetails?.lawyers[0]?.firstName + ' ' : ''
      }${this.matterDetails?.lawyers[0]?.lastName ? this.matterDetails?.lawyers[0]?.lastName : ''}`;
      const address = `${
        this.matterDetails?.lawyers[0]?.streetNumber ? this.matterDetails?.lawyers[0]?.streetNumber + ' ' : ''
      }${this.matterDetails?.lawyers[0]?.streetName ? this.matterDetails?.lawyers[0]?.streetName + '\\n' : '\\n'}${
        this.matterDetails?.lawyers[0]?.city ? this.matterDetails?.lawyers[0]?.city + ' ' : ''
      }${this.matterDetails?.lawyers[0]?.province ? this.matterDetails?.lawyers[0]?.province + ', ' : ', '}${
        this.matterDetails?.lawyers[0]?.postalCode ? this.matterDetails?.lawyers[0]?.postalCode : ''
      }`;
      const contactNumber = this.matterDetails?.lawyers[0]?.mobilePhone
        ? this.matterDetails?.lawyers[0]?.mobilePhone
        : '';
      const email = this.matterDetails?.lawyers[0]?.email ? this.matterDetails?.lawyers[0]?.email : '';
      this.applicantInfo = `${this.applicantLawyerName !== '' ? this.applicantLawyerName + ', ' : ''}${
        address !== '' ? address + ', \\n' : ''
      }${contactNumber !== '' ? contactNumber + ', \\n' : ''}${email !== '' ? email : ''}`;
    } else {
      this.respondentName = `${this.matterDetails?.user?.firstName ? this.matterDetails?.user?.firstName + ' ' : ''}${
        this.matterDetails?.user?.lastName ? this.matterDetails?.user?.lastName : ''
      }`;
      const respondentAddress = `${
        this.matterDetails?.mailingAddress?.streetNumber ? this.matterDetails?.mailingAddress?.streetNumber + ' ' : ''
      }${
        this.matterDetails?.mailingAddress?.streetName ? this.matterDetails?.mailingAddress?.streetName + ', ' : ', '
      }${this.matterDetails?.mailingAddress?.city ? this.matterDetails?.mailingAddress?.city + ' ' : ''}${
        this.matterDetails?.mailingAddress?.province ? this.matterDetails?.mailingAddress?.province + ', ' : ', '
      }${this.matterDetails?.mailingAddress?.postalCode ? this.matterDetails?.mailingAddress?.postalCode : ''}`;
      const respondentContactNumber = this.matterDetails?.user?.mobilePhone
        ? this.matterDetails?.user?.mobilePhone
        : '';
      const respondentEmail = this.matterDetails?.user?.email ? this.matterDetails?.user?.email : '';
      this.infoApplicant = `${this.respondentName !== '' ? this.respondentName + ', ' : ''}${
        respondentAddress !== '' ? respondentAddress + ', \\n' : ''
      }${respondentContactNumber !== '' ? respondentContactNumber + ', \\n' : ''}${
        respondentEmail !== '' ? respondentEmail : ''
      }`;

      this.respondentLawyerName = `${
        this.matterDetails?.lawyers[0]?.firstName ? this.matterDetails?.lawyers[0]?.firstName + ' ' : ''
      }${this.matterDetails?.lawyers[0]?.lastName ? this.matterDetails?.lawyers[0]?.lastName : ''}`;
      const address = `${
        this.matterDetails?.lawyers[0]?.streetNumber ? this.matterDetails?.lawyers[0]?.streetNumber + ' ' : ''
      }${this.matterDetails?.lawyers[0]?.streetName ? this.matterDetails?.lawyers[0]?.streetName + '\\n' : '\\n'}${
        this.matterDetails?.lawyers[0]?.city ? this.matterDetails?.lawyers[0]?.city + ' ' : ''
      }${this.matterDetails?.lawyers[0]?.province ? this.matterDetails?.lawyers[0]?.province + ', ' : ', '}${
        this.matterDetails?.lawyers[0]?.postalCode ? this.matterDetails?.lawyers[0]?.postalCode : ''
      }`;
      const contactNumber = this.matterDetails?.lawyers[0]?.mobilePhone
        ? this.matterDetails?.lawyers[0]?.mobilePhone
        : '';
      const email = this.matterDetails?.lawyers[0]?.email ? this.matterDetails?.lawyers[0]?.email : '';
      this.applicantInfo = `${this.respondentLawyerName !== '' ? this.respondentLawyerName : ''}\\n${
        address !== '' ? address + '\\n' : ''
      }${contactNumber !== '' ? contactNumber + ', \\n' : ''}${email !== '' ? email : ''}`;
    }
  }

  getSubscribedUser() {
    this.api
      .get(Endpoints.subscriber)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.userDetails = res;
          this.userDetails.userInfo = JSON.parse(localStorage.getItem('userDetails'));
        }
      });

    this.api
      .get(Endpoints.user)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.currentUser = res;
          this.getSignature();
        }
      });
  }

  onSelectRecipients(ev: any) {
    this.recipientSelected = ev.target.value;
    if (this.matterDetails?.matterType.toLowerCase() === 'immigration') {
      this.selectedItems = ev.target.value !== 'Client' ? [{ id: 'Client', name: 'Client' }] : [];
    }

    if (ev?.target?.value !== '') {
      if (this.matterDetails.matterType === 'Immigration') {
        this.options.to = ev.target.value;
        this.dataService.setPrecedentOptions(this.options);
        if (this.recipientSelected === 'Immigration and Refugee Board') {
          this.jury.forEach(jurisdiction => {
            if (this.matterDetails?.jurisdiction === jurisdiction.name) {
              this.setJurisdictionAddress = `Immigration and Refugee Board\\n${jurisdiction.address}`;
            }
          });
        } else if (this.recipientSelected === 'Client') {
          const data = this.userMatterDetails.matterDetails;
          const name = `${data?.user?.firstName} ${data?.user?.middleName !== null ? data?.user?.middleName : ''} ${
            data?.user.lastName
          }`;
          const address = `${data.mailingAddress?.streetNumber ? data.mailingAddress?.streetNumber : ''} ${
            data.mailingAddress?.streetName ? data.mailingAddress?.streetName : ''
          }\\n${data.mailingAddress?.city ? data.mailingAddress?.city : ''} ${
            data.mailingAddress?.province ? data.mailingAddress?.province : ''
          } ${data.mailingAddress?.postalCode ? data.mailingAddress?.postalCode : ''} ${
            data.mailingAddress?.country ? data.mailingAddress?.country : ''
          }`;
          const email = data?.user?.email;
          const phone = data?.user?.mobilePhone ? data?.user?.mobilePhone : '';
          this.setJurisdictionAddress = `${name}\\n${address}\\n${email}\\n${phone}`;
        } else if (this.recipientSelected === 'IRCC') {
          if (!this.userMatterDetails.matterDetails?.ircc) {
            this.toastr.error('IRCC address not found');
            this.unsetCheckedPrecedent();
            return;
          }
          this.setJurisdictionAddress = this.userMatterDetails.matterDetails?.ircc?.replaceAll('\n', '\\n');
        } else if (this.recipientSelected === 'CBSA') {
          if (!this.userMatterDetails.matterDetails?.cbsaDetails) {
            this.toastr.error('CBSA address not found');
            this.unsetCheckedPrecedent();
            return;
          }
          this.setJurisdictionAddress = this.userMatterDetails.matterDetails?.cbsaDetails?.replaceAll('\n', '\\n');
        } else if (this.recipientSelected === 'Designated Representative') {
          if (!this.userMatterDetails.matterDetails?.designatedRep) {
            this.toastr.error('Designated Representative address not found');
            this.unsetCheckedPrecedent();
            return;
          }
          this.setJurisdictionAddress = this.userMatterDetails.matterDetails?.designatedRep?.replaceAll('\n', '\\n');
        }
      } else if (this.matterDetails.matterType === 'Family') {
        if (this.recipientSelected === this.applicantName) {
          this.setJurisdictionAddress = this.infoApplicant;
        } else if (this.recipientSelected === this.respondentName) {
          this.setJurisdictionAddress = this.infoRespondent;
        } else if (this.recipientSelected === this.applicantLawyerName) {
          this.setJurisdictionAddress = this.applicantInfo;
        } else if (this.recipientSelected === this.respondentLawyerName) {
          this.setJurisdictionAddress = this.respondentInfo;
        } else if (this.recipientSelected === this.matterDetails.court) {
          this.setJurisdictionAddress = `${this.matterDetails?.court}\\n${
            this.matterDetails?.courtAddress ? this.matterDetails?.courtAddress : ''
          }`;
        }
      }
    }
  }

  openSettings() {
    this.addBlock.show();
  }

  addPrecedentInfo() {
    if (this.addPrecedent.invalid) {
      return;
    }
    const precedentData = {
      category: this.catId?._id || this.selectedCategory?._id,
      precedent: this.addPrecedent.value.precedentDesc,
      matterType: this.matterDetails.matterType,
    };
    this.spinner.show();
    this.api
      .post(`${Endpoints.precedent}`, precedentData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          this.addPrecedent.reset();
          this.spinner.hide();
          this.addBlock.hide();
          this.getPrecendents();
          this.toastr.success(MESSAGE.TOASTR.MESSAGE_ADDPRECEDENT, MESSAGE.INFO_MESSAGES.SUCCESS);
        },
        err => {
          this.spinner.hide();
        },
      );
  }

  onCancelPrecedent() {
    this.addBlock.hide();
    this.addPrecedent.reset();
  }

  selectCategory(ev: any) {
    this.addPrecedent.controls['category'].setValue(ev.target.value);
    this.catId = this.precedentCat.filter(cat => cat.name === ev.target.value)[0];
  }

  onDragStart(event: DragEvent) {
    this.hideToolTip = false;
    this.currentDragEffectMsg = '';
    this.currentDraggableEvent = event;
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    this.currentDragEffectMsg = `Drag ended with effect "${effect}"!`;
    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDragEnd(event: DragEvent) {
    this.currentDraggableEvent = event;
    this.updateOrder();
    this.hideToolTip = true;
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    this.commonService.allowUpdate.next(true);

    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);

      this.precedentList.forEach((item, i) => {
        item.order = i + 1;
      });
    }
  }

  updateOrder() {
    this.spinner.show();
    this.api
      .put(Endpoints.updatePrecedentOrder, this.precedentList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.spinner.hide();
          this.commonService.allowUpdate.next(false);
          this.toastr.success(MESSAGE.TOASTR.MESSAGE_ORDERUPDATED, MESSAGE.INFO_MESSAGES.SUCCESS);
        } else {
          this.spinner.hide();
        }
      });
  }

  blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  ngOnDestroy(): void {
    this.dataService.removeItem('precedentOptions');
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
