import {
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad/lib/angular-signature-pad.component';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Endpoints } from 'app/config';
import { ApiService, AuthService } from 'app/services';
import { FormsService } from 'app/services/forms.service';
import { ReceiptData, UserData } from 'app/shared/models/paymentReceipt';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cash-payment-receipt',
  templateUrl: './cash-payment-receipt.component.html',
  styleUrls: ['./cash-payment-receipt.component.scss'],
})
export class CashPaymentReceiptComponent implements OnInit, OnDestroy {
  paymentData: any;
  @ViewChild('addSignature', { static: true }) public addSignature: ModalDirective;
  @ViewChild('signature') public signaturePad: SignaturePadComponent;
  @Input() receiptData: ReceiptData;
  @Input() lawyerData: UserData;
  lawyersSignature = new FormControl();
  allowSignature = false;
  signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 460,
    canvasHeight: 200,
    penColor: 'blue',
  };
  receiptId: string;
  date = moment().format('MMMM DD, YYYY');
  recieptPaymentData: any;
  receiptDetails: ReceiptData;
  clientSignature: string;
  lawyerSignature: string;
  invoiceNo: number;
  isLawyerSignature: boolean;
  isExpired = false;
  isClientSignature = false;
  getApproverName: string;
  hideForm = false;
  destroy$ = new Subject<boolean>();
  hideButton = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private formService: FormsService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params) {
        this.receiptId = params['receiptId'];
      }
    });
    this.getReceiptData();
    this.hideButton = window.location.href === `${window.location.origin}/#/accounts`;
    if (this.receiptData) {
      this.receiptDetails = this.receiptData;
    }
  }

  getReceiptData(): void {
    if (this.receiptId) {
      this.apiService
        .getParams(Endpoints.getPaymentReceipt, { receiptId: this.receiptId })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res && res.paymentReciptData) {
            this.paymentData = res.paymentReciptData;
            if (this.paymentData) {
              const urlExpired = this.formService.isUrlExpired(this.paymentData.clientData?.createdAt);
              this.isClientSignature = Boolean(this.paymentData.clientData?.clientSignature);
              this.isLawyerSignature = Boolean(this.paymentData.clientData.lawyerSignature);
              if (this.isClientSignature) {
                this.hideForm = true;
              } else if (!this.isClientSignature && urlExpired) {
                this.isExpired = true;
                this.hideForm = true;
              }
              this.setData();
            }
          }
        });
    }
  }

  setData(): void {
    if (this.paymentData && this.paymentData.lawyerData && this.paymentData.clientData) {
      this.receiptDetails = {
        lawyerName: `${this.paymentData.lawyerData.firstName} ${this.paymentData.lawyerData.lastName}`,
        lawyerDesignation: `${this.paymentData.lawyerData.designations}`,
        lawyerAddress: `${this.paymentData.lawyerData.streetNumber} ${this.paymentData.lawyerData.streetName}, ${this.paymentData.lawyerData.city} ${this.paymentData.lawyerData.province}, ${this.paymentData.lawyerData.postalCode}`,
        lawyerTelephone: this.paymentData.lawyerData.homePhone,
        lawyerEmail: this.paymentData.lawyerData.email,
        invoiceNo: this.paymentData.clientData.invoiceNo,
        courtFileNo: this.paymentData.clientData.courtFileNo,
        paymentReceiptData: {
          clientAmount: this.paymentData.clientData.paymentReceiptData.clientAmount,
          clientType: this.paymentData.clientData.paymentReceiptData.clientType,
        },
        client: this.paymentData.clientData.client,
      };

      if (this.receiptDetails?.paymentReceiptData?.clientType === 'existClient') {
        this.receiptDetails.paymentReceiptData.existClient =
          this.paymentData.clientData.paymentReceiptData.clientName || this.paymentData.clientData.client;
      } else {
        this.receiptDetails.paymentReceiptData.thirdPartyClient =
          this.paymentData.clientData.paymentReceiptData.clientName;
      }
    }
  }

  submitCashPaymentForm(): void {
    const userData = this.authService.getUserDetails();

    const data = {
      paymentReceiptData: this.paymentData?.clientData.paymentReceiptData || this.receiptDetails?.paymentReceiptData,
      redirectUrl: `${window.location.origin}/#/cash-payment-receipt`,
      matterId: this.paymentData?.clientData.matterId || this.receiptDetails?.matterId,
      lawyerId: this.paymentData?.clientData.lawyerId || this.receiptDetails?.lawyerId,
      courtFileNo: this.paymentData?.clientData.courtFileNo || this.receiptDetails?.courtFileNo,
      clientSignature: this.clientSignature || this.receiptDetails?.clientSignature,
      lawyerSignature: this.lawyerSignature,
      update: this.clientSignature || this.lawyerSignature ? true : false,
      invoiceNo: this.invoiceNo || this.receiptDetails?.invoiceNo,
      getApproverName: `${userData?.firstName || ''} ${userData?.lastName || ''}`,
      isClerk: userData?.accessLevel === 2,
      _id: this.paymentData?.clientData._id || this.receiptDetails?._id,
      existClientEmail: this.receiptDetails?.existClientEmail,
      client: this.receiptDetails?.client,
      formattedDate: moment(this.receiptDetails?.createdAt).format('MMMM DD YYYY'),
    };

    this.spinner.show();
    this.apiService
      .put(Endpoints.paymentReceipt, data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          if (this.isLawyerSignature === true) {
            this.formService.isLawyerSign.next(true);
          }
          this.toastr.success(response.message, 'Success');
          this.spinner.hide();
        }
      }),
      error => {
        console.log('TCL ->  ~ CashPaymentReceiptComponent ~ submitCashPaymentForm ~ error:', error);
        this.spinner.hide();
      };
  }

  openSignatureModal(): void {
    this.signaturePad.clear();
    this.addSignature.show();
  }

  onDrawStart(): void {
    this.allowSignature = true;
  }

  submitSignature(): void {
    const image = this.signaturePad.toDataURL();
    if (image) {
      this.clientSignature = image;
      if (this.clientSignature) {
        this.isClientSignature = true;
        this.submitCashPaymentForm();
      }
    }
    this.addSignature.hide();
  }

  public isInValid(): boolean {
    return !(this.signaturePad && !this.signaturePad.isEmpty());
  }

  formatDesignation(designation: string): string {
    return designation || '';
  }

  async insertLawyerSignature() {
    try {
      const lawyerId = this.paymentData?.clientData.lawyerId || this.receiptDetails?.lawyerId;

      if (!this.lawyersSignature.value && lawyerId) {
        this.spinner.show();
        const signature = await this.formService.getLawyerSignature('', lawyerId, this.lawyersSignature);
        this.spinner.hide();
        if (signature) {
          this.isLawyerSignature = true;
          this.lawyerSignature = this.lawyersSignature.value;
          this.submitCashPaymentForm();
        }
      } else {
        this.lawyersSignature.setValue('');
      }
    } catch (error) {
      this.spinner.hide();
      this.toastr.error('Signature not found', 'Error');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
