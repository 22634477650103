import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService, DataService } from '../services';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  token: string;
  constructor(private router: Router, private auth: AuthService, private data: DataService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.auth.setHeader();
    request = request.clone({ headers: this.auth.headers });

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (event.body) {
              const token = event.headers.get('X-auth-token');

              if (token) {
                this.auth.setToken(token);
                this.data.setUserDetail(event.body);
              }
            }
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            if (error?.status === 401) {
              const url = window.location.pathname;
              if (url !== '/auth/login') {
                this.data.setRedirectUrl(url);
              }

              this.router.navigateByUrl('/auth/login');
            }
            return throwError(error);
          }
        },
      ),
    );
  }
}
